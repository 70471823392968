.custom-modal {
  max-width: 80vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-indicator {
  display: block;
  margin: 1em auto;
}

// DOCUMENTS
.item-col {
  &:hover {
    cursor: pointer;
    background-color: #f0f3f5;

    .card-header {
      background-color: darken(#f0f3f5, 5%);
    }
  }
}

.row.first-in-container {
  padding-top: 30px;
}

.card.drive-item {
  .card-footer {
    padding: 0;
  }

  .drive-item-actions {
    width: 100%;
  }
}

.item-icon {
  font-size: 80px;
  text-align: center;

  &.item-icon-file {
    color: #a8a8a8;
  }

  &.item-icon-file-alt {
    color: #a8a8a8;
  }

  &.item-icon-folder {
    color: #fdcb6e;
  }

  &.item-icon-powerpoint {
    color: #e17055;
  }

  &.item-icon-word {
    color: #0984e3;
  }

  &.item-icon-excel {
    color: #00b894;
  }

  &.item-icon-pdf {
    color: #ff7675;
  }

  &.item-icon-image {
    color: #a29bfe;
  }

  &.item-icon-video {
    color: #6c5ce7;
  }

  &.item-icon-audio {
    color: #00cec9;
  }

  &.item-icon-archive {
    color: #b8e994;
  }
}

// stack button group on mobile
@media only screen and (max-width: 767px) {
  .btn-group {
    display: block;
  }

  .btn-group .btn {
    display: block;
    float: none;
    width: 100%;
  }
}

// LOGIN PAGE
.login-logo {
  padding-bottom: 2em;
  max-width: 400px;
  width: 100%;
}

.force-bg-primary {
  background-color: $primary !important;
}

._form_16 {
  font-family: inherit !important;

  form._form_16 {
    border: none !important;
    font-family: inherit !important;
    width: auto !important;
    padding: 0 !important;
  }

  button#_form_16_submit {
    font-family: inherit !important;
  }
}

@media (min-width: 991.98px) {
  .login-card {
    width: 44%;
  }
}

// HEADER
.app-header .navbar-brand.phr {
  justify-content: left;

  img {
    height: 90%;
    padding: 0.2em 5px;
  }
}

.dropwrapper {
  height: 100%;
}

.breadnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin: 1%;
}
